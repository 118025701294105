import { render, staticRenderFns } from "./MeanArterialPressure.vue?vue&type=template&id=36d04a27&scoped=true&"
import script from "./MeanArterialPressure.vue?vue&type=script&lang=js&"
export * from "./MeanArterialPressure.vue?vue&type=script&lang=js&"
import style0 from "./MeanArterialPressure.vue?vue&type=style&index=0&id=36d04a27&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36d04a27",
  null
  
)

export default component.exports